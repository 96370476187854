import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import DigitalPublication from '../components/digitalpublication';

const DigitalPublications = (props) => (
  <Layout>
    <Helmet>
      <title>View our Work - Mills Publishing Inc.</title>
      <meta
        name="description"
        content="View Mills Publishing's Publications."
      />
    </Helmet>

    <div id="main">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>View our Work</h1>
          </header>
          Enjoy browsing through our publications we have produced for our
          amazing clients. The buttons below showcase just some of the options
          available to our advertisers.
          <DigitalPublication />
        </div>
      </section>
    </div>
  </Layout>
);

export default DigitalPublications;
